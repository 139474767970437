function isRegisterFileExist(regulator, path) {
  let components = require.context(`@/components/register/`, true, /^\.\/.*\.vue$/)

  let isExist = false
  if (components) {
    let filePath = `${path}/${regulator}`
    components.keys().map(requireContext => {
      const fileName = requireContext.replace('./', '').replace('.vue', '')
      if (fileName === filePath) {
        isExist = true
      }
    })
  }

  return isExist
}

export { isRegisterFileExist }
