import Axios from 'axios'
import router from '@/router'
import { startLoading, endAjaxLoading } from '@/util/loading'

export const mimeType = Object.freeze({
    '.pdf': 'application/pdf',
    '.rtf': 'application/rtf',
    '.json': 'application/json',
    '.js': 'application/javascript',

    '.png': 'image/png',
    '.jpg': 'image/jpeg',
    '.jpe': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.svg': 'image/svg+xml',
    '.tif': 'image/tiff',
    '.tiff': 'image/tiff',
    '.webp': 'image/webp',
    '.bmp': 'image/bmp',
    '.gif': 'image/gif',

    '.css': 'text/css',
    '.csv': 'text/csv',
    '.htm': 'text/html',
    '.html': 'text/html',

    '.txt': 'text/plain',
    '.doc': 'application/msword',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
})
export const allowTypes = Object.freeze({
    'image/png': 'img',
    'image/jpeg': 'img',
    'image/jpeg': 'img',
    'image/jpeg': 'img',
    'image/svg+xml': 'img',
    'image/tiff': 'img',
    'image/tiff': 'img',
    'image/webp': 'img',
    'image/bmp': 'img',
    'image/gif': 'img',

    'application/pdf': 'iframe'
})

export const getFileExtensionByMimeType = mimeTypeString => {
    return Object.keys(mimeType).find(key => mimeTypeString.includes(mimeType[key])) || ''
}

export const urlToFile = async url => {
    try {
        startLoading('ajax')
        const response = await fetch('/api' + url)
        const blob = await response.blob()
        console.log(blob, getFileExtensionByMimeType(blob.type))
        let data = new File([blob], `file${getFileExtensionByMimeType(blob.type)}`, { type: blob.type })
        // let l = await uploadFile(data)
        endAjaxLoading()
        return data
    } catch (e) {
        endAjaxLoading()
        return null
    }
}




