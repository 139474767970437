<template>
  <component :is="currentComponent" isIBT></component>
</template>

<script>
import { getRegisterComponent } from '@/util/getFileComponent';

export default {
  name: 'IbtIDProofChildView',
  data() {
    return {
      routerName: 'ibtProof/' + this.$route.name
    };
  },
  computed: {
    currentComponent() {
      return getRegisterComponent(this.routerName, this.regulator);
    }
  }
};
</script>
