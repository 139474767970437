<template>
  <IdProof :nextStep="nextStep" :preStep="preStep" isIBT />
</template>

<script>
import IdProof from '@/components/register/components/IdProof';
import { REGISTERROUTER, REBATE_WITHDRAW_PATHNAME } from '@/constants/route';
import { mapState, mapActions } from 'vuex';
import { statusConfig } from '@/constants/status';
import { checkRegisterPageCanAudit } from '@/util/register';

export default {
  name: 'IbtIDProof',
  components: { IdProof },
  computed: {
    ...mapState('register', ['ibtPoiAuditStatus', 'ibtPoaAuditStatus']),
    nextStep() {
      // only init, pending, rejected can enter IBT poa page
      return !checkRegisterPageCanAudit(this.ibtPoaAuditStatus)
        ? REBATE_WITHDRAW_PATHNAME
        : REGISTERROUTER.ADDRESS_PROOF;
    },
    preStep() {
      const notYetDoIbtProof =
        this.ibtPoiAuditStatus === statusConfig.init && this.ibtPoaAuditStatus === statusConfig.init;
      return notYetDoIbtProof ? REGISTERROUTER.FINANCIAL_INFO : null;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('common', ['actionUpdateId3Pass']),
    ...mapActions('register', ['actionUpdateIBTStatus']),
    async init() {
      // update id3Pass status
      await this.actionUpdateId3Pass();
      // update ibtPoa, ibtPoi status
      await this.actionUpdateIBTStatus();
    }
  }
};
</script>
