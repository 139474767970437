var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resizeObserver",rawName:"v-resizeObserver",value:({
    openResize: true,
    handler: _vm.observerCallBack
  }),expression:"{\n    openResize: true,\n    handler: observerCallBack\n  }"}],staticClass:"pug-upload",class:[!_vm.fileInfo.fileList.length ? 'no-file' : '' ].concat( _vm.calCustomClasseses),style:({
    height: _vm.calHeight,
    width: _vm.calWidth
  })},[_c('el-upload',_vm._g(_vm._b({directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],ref:"uploadRef",staticClass:"upload-container",attrs:{"action":_vm.action,"list-type":_vm.calUploadListType,"limit":_vm.calLimit,"multiple":_vm.calMultiple,"accept":_vm.acceptFiles,"before-upload":_vm.examFile,"on-success":_vm.handleOnSuccess,"on-error":_vm.uploadError,"data-testid":_vm.calDataTestId,"file-list":_vm.files}},'el-upload',_vm.$attrs,false),_vm.$listeners),[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"upload-area",class:{ 'pre-view': _vm.showPreViewImage }},[(_vm.hasUploadContent)?_vm._t("uploadContent"):_c('div',{class:_vm.calCustomContentClasses,attrs:{"data-testid":"button_upload"}},[_vm._t("uploadTopContent"),_c('div',{staticClass:"upload-add"},[_c('img',{attrs:{"src":require("@/assets/images/common/add-icon.png"),"alt":"upload-icon"}})]),_vm._t("uploadBottomContent")],2)],2),(_vm.pdfSource && !_vm.isLoading)?_c('vue-pdf-embed',{ref:"pdfRef",attrs:{"source":_vm.pdfSource,"width":_vm.pdfPreviewWidth,"page":1},on:{"rendered":_vm.setPreviewImgPositionEvent}}):_vm._e()],1),_c('div',{staticClass:"actions-item fullscreen-btn",attrs:{"data-testid":"fullscreen"},on:{"click":_vm.handlePreViewFile}},[_c('img',{attrs:{"src":require("@/assets/images/common/fullscreen.png"),"alt":"preview-file"}})]),_c('div',{staticClass:"actions-item delete-btn",attrs:{"data-testid":"deleteFile"},on:{"click":function($event){_vm.showSureToDelete = true}}},[_c('img',{attrs:{"src":require("@/assets/images/common/delete.png"),"alt":"delete-file"}})]),(_vm.showPreViewImage && _vm.fileInfo.fileList && !_vm.isCurrentFilePdf)?_c('ElImageViewer',{attrs:{"on-close":_vm.closeViewer,"z-index":1000,"url-list":[].concat( _vm.fileInfo.fileList ),"hide-on-click-modal":""}}):_vm._e(),_c('el-dialog',{directives:[{name:"resizeObserver",rawName:"v-resizeObserver",value:({
      openResize: true,
      handler: _vm.observerElDialogCallBack
    }),expression:"{\n      openResize: true,\n      handler: observerElDialogCallBack\n    }"}],staticClass:"pdf-previewer",attrs:{"visible":_vm.showPreViewPdf,"fullscreen":"","closeOnClickModal":"","width":"100%"},on:{"close":function($event){_vm.showPreViewPdf = false}}},[_c('div',{staticClass:"close-preview-btn",on:{"click":function($event){_vm.showPreViewPdf = false}}},[_c('i',{staticClass:"el-icon-circle-close"})]),(_vm.showPreViewPdf)?_c('vue-pdf-embed',{ref:"previewPdfRef",attrs:{"source":_vm.pdfSource,"width":_vm.pdfAreaWidth}}):_vm._e()],1),_c('InfoDialog',{attrs:{"visible":_vm.showSureToDelete,"title":_vm.$t('fileUploader.sureToDelete.title'),"infoType":"fail"},on:{"update:visible":function($event){_vm.showSureToDelete=$event},"closCbDialog":function($event){_vm.showSureToDelete = false}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('Button',{attrs:{"buttonType":"default"},on:{"click":_vm.handleRemoveSingleFile}},[_vm._v(" "+_vm._s(_vm.$t('common.button.ok'))+" ")])]},proxy:true}])},[_c('div',[_vm._v(_vm._s(_vm.$t('fileUploader.sureToDelete.content')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }