<template>
  <AddressProof :nextStep="nextStep" :preStep="preStep" isIBT />
</template>

<script>
import AddressProof from '@/components/register/components/AddressProof';
import { REGISTERROUTER, REBATE_WITHDRAW_PATHNAME } from '@/constants/route';
import { mapState, mapActions } from 'vuex';
import { statusConfig } from '@/constants/status';

export default {
  name: 'IbtAddressProof',
  components: { AddressProof },
  data() {
    return {
      nextStep: REBATE_WITHDRAW_PATHNAME
    };
  },
  computed: {
    ...mapState('register', ['ibtPoiAuditStatus', 'ibtPoaAuditStatus']),
    preStep() {
      // only init, pending, rejected can enter IBT poa page
      return this.ibtPoiAuditStatus === statusConfig.submitted ||
        this.ibtPoiAuditStatus === statusConfig.completed ||
        this.ibtPoiAuditStatus === statusConfig.reaudit
        ? null
        : REGISTERROUTER.ID_PROOF;
    }
  },
  mounted() {
    // update ibtPoa, ibtPoi status
    this.init();
  },
  methods: {
    ...mapActions('common', ['actionUpdateId3Pass']),
    ...mapActions('register', ['actionUpdateIBTStatus']),
    async init() {
      await this.actionUpdateId3Pass();
      await this.actionUpdateIBTStatus();
    }
  }
};
</script>
