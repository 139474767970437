<template>
  <el-form :model="formData" ref="formData" class="body-box">
    <div class="form-box mb-5">
      <el-row :gutter="40">
        <el-col :sm="12" :xs="24" v-for="(questionEmployee, index) in employmentFinanceSelections" :key="index">
          <el-form-item
            :prop="'employmentFinanceAnswers.' + index + '.answers[0]'"
            :rules="getSelectionRule(questionEmployee.desc)"
          >
            <Selection
              :title="questionEmployee.desc"
              v-model="formData.employmentFinanceAnswers[questionEmployee.questionId - 1].answers[0]"
              :placeholder="$t('common.formValidation.answerReq')"
              :options="questionEmployee.questionOptions"
              filterable
              selectType="underline"
              :disabled="formData.employmentFinanceAnswers[questionEmployee.questionId - 1].disabled"
              :dataTestId="`question${questionEmployee.questionId}`"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <div class="title-box mb-5">
      <h4 class="sub-title">{{ $t('register.financialVerification.financialInfo.subTitle2') }}</h4>
    </div>
    <div class="form-box mb-5">
      <el-row :gutter="40">
        <el-col :sm="12" :xs="24" v-for="(questionTrading, index) in tradingAnswersSelections" :key="index">
          <el-form-item
            :prop="'tradingAnswers.' + index + '.answers[0]'"
            :rules="getSelectionRule(questionTrading.desc)"
          >
            <Selection
              :title="questionTrading.desc"
              v-model="
                formData.tradingAnswers[questionTrading.questionId - tradingAnswersSelections[0].questionId].answers[0]
              "
              :placeholder="$t('common.formValidation.answerReq')"
              :options="questionTrading.questionOptions"
              filterable
              selectType="underline"
              :disabled="
                formData.tradingAnswers[questionTrading.questionId - tradingAnswersSelections[0].questionId].disabled
              "
              :dataTestId="`question${questionTrading.questionId}`"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <div class="button-box">
      <Button
        :customClasses="['m-auto', 'action-button']"
        @click="submitForm('formData')"
        dataTestId="financialInfoSubmit"
      >
        <div>{{ $t('register.btn.next') }}</div>
      </Button>
    </div>
  </el-form>
</template>

<script>
import Button from '@/components/common/Button.vue';
import TextInput from '@/components/common/TextInput.vue';
import Selection from '@/components/common/Selection.vue';
import { apiEmploymentFinance, apiTrading, apiIbtGetData, apiSubmitIbtQuestionnaire } from '@/resource/register';
import { mapActions } from 'vuex';
import { getStatus, getNextRegisterStep } from '@/util/register';

export default {
  name: 'FinancialInfo',
  components: { Button, TextInput, Selection },
  data() {
    return {
      formData: {
        employmentFinanceAnswers: [],
        tradingAnswers: []
      },
      employmentFinanceSelections: [],
      tradingAnswersSelections: [],
      selectionRule: {
        required: true,
        message: this.$t('common.formValidation.dynamicReq', {
          dynamic: this.$t('register.poaVerification.employmentStatus')
        }),
        trigger: ['blur', 'change']
      }
    };
  },
  computed: {
    currentLang() {
      return this.$store.state.common.lang;
    }
  },
  mounted() {
    this.getOptionsAndIbtData();
  },
  methods: {
    ...mapActions('register', ['actionChangeIbtPoiAuditStatus', 'actionChangeIbtPoaAuditStatus', 'actionStep']),
    async getOptionsAndIbtData() {
      const self_formatSelectOptions = options => {
        if (options?.length) {
          return options.map(item => ({
            label: item.desc,
            value: item.id,
            disabled: false
          }));
        }
        return [];
      };
      const self_initailFormData = (apiData, formDataKey) => {
        if (apiData && apiData?.data.code === 0 && apiData?.data?.data?.length) {
          const res = [...apiData.data.data];
          res.forEach((item, index) => {
            item.questionOptions = self_formatSelectOptions(item.questionOptions);
            this.formData[formDataKey].push({
              questionId: item.questionId,
              answers: [item?.questionOptions[0]?.value]
            });
          });
          return res;
        }
      };
      const self_setPreviousUserData = (data, formDataKey) => {
        if (data?.length) {
          data.forEach(item => {
            const targetIndex = this.formData[formDataKey].findIndex(element => element.questionId === item.questionId);
            if (targetIndex !== -1) {
              this.formData[formDataKey][targetIndex].answers = item.answers;
              this.formData[formDataKey][targetIndex].disabled = false;
            }
          });
        }
      };

      try {
        // get options for employmentFinanceOptions, tradingAnswersOptions & IBT data
        const optionsData = await Promise.all([
          await apiEmploymentFinance(this.currentLang),
          await apiTrading(this.currentLang),
          await apiIbtGetData()
        ]);
        // set default Employment and Financial Details options
        this.employmentFinanceSelections = self_initailFormData(optionsData[0], 'employmentFinanceAnswers');
        // set default Trading Knowledge and Experience options
        this.tradingAnswersSelections = self_initailFormData(optionsData[1], 'tradingAnswers');

        // set previous user data for options
        if (optionsData[2] && optionsData[2]?.data.code === 0) {
          const {
            employmentFinanceAnswers,
            tradingAnswers,
            ibtPoaAuditStatus,
            ibtPoiAuditStatus
          } = optionsData[2].data.data;

          // set previous user data
          const previousData = this.$route.params;
          if (previousData?.financialInfo && Object.keys(previousData?.financialInfo).length) {
            self_setPreviousUserData(previousData?.financialInfo?.employmentFinanceAnswers, 'employmentFinanceAnswers');
            self_setPreviousUserData(previousData?.financialInfo?.tradingAnswers, 'tradingAnswers');
          }

          // store IBT poi, poa status in store
          if (ibtPoiAuditStatus) this.actionChangeIbtPoiAuditStatus(getStatus(ibtPoiAuditStatus));
          if (ibtPoaAuditStatus) this.actionChangeIbtPoaAuditStatus(getStatus(ibtPoaAuditStatus));
        }
      } catch (error) {
        this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
      }
    },
    getSelectionRule(columnName) {
      return {
        required: true,
        message: this.$t(`common.formValidation.dynamicReq`, {
          dynamic: columnName
        }),
        trigger: ['blur', 'change']
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            // await apiSubmitIbtQuestionnaire(this.formData);
            this.actionStep(getNextRegisterStep(this.$route.name));
            // this.$router.push('iDProof');

            // const preStepsData = this.$route.params;
            this.$router.push({ name: 'iDProof', params: { ...this.$route.params, financialInfo: this.formData } });
          } catch (error) {
            this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/registerNew.scss';

.action-button {
  width: 160px;
  height: 56px;
}

@media (max-width: 770px) {
  .action-button {
    width: 100%;
  }
}
</style>
