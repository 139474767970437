<template>
  <FinancialInfo />
</template>

<script>
import FinancialInfo from '@/components/register/components/FinancialInfo';

export default {
  name: 'FinantialInfoPage',
  components: { FinancialInfo }
};
</script>
