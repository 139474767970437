<template>
  <div :class="[calInputType, calError, ...calCustomClasses]">
    <div class="input-title" :class="{ 'on-focus': isFocus }">
      <p v-if="calTitle">{{ calTitle }}</p>
      <slot v-else name="title"></slot>
    </div>
    <el-input
      :class="{ 'has-content': hasContent }"
      v-model="currentValue"
      v-bind="$attrs"
      v-on="$listeners"
      @focus="isFocus = true"
      @blur="isFocus = false"
      :data-testid="calDataTestId"
    >
    </el-input>
  </div>
</template>

<script>
import { handleXssInput } from '@/util/helper';

export default {
  name: 'PugTextInput',
  model: {
    prop: 'inputData',
    event: 'returnModel'
  },
  props: {
    inputData: {
      type: String | Number | Array | Object | Boolean,
      default: ''
    },
    inputType: {
      type: String,
      default: 'underline' // underline, outline
    },
    error: {
      type: Boolean,
      default: false
    },
    customClasses: {
      type: Array | String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    dataTestId: {
      type: String | Number,
      default: 'textInput'
    },
    inputLimitation: {
      type: Function | null,
      default: null
    }
  },
  data() {
    return {
      isFocus: false
    };
  },
  computed: {
    calDataTestId() {
      return this.dataTestId;
    },
    currentValue: {
      get() {
        return this.inputData;
      },
      set(newValue) {
        let handleValue = handleXssInput(newValue);
        if (this.inputLimitation) {
          handleValue = this.inputLimitation(handleValue);
        }

        this.$emit('returnModel', handleValue);
        return handleValue;
      }
    },
    calInputType() {
      return this.inputType;
    },
    hasContent() {
      return this.currentValue ? true : false;
    },
    calError() {
      return this.error ? 'error' : '';
    },
    calCustomClasses() {
      return typeof this.customClasses === 'string' ? [this.customClasses] : [...this.customClasses];
    },
    calTitle() {
      return this.title;
    }
  }
};
</script>

<style lang="scss" scoped>
.underline,
.outline {
  .input-title {
    font-weight: 400;
    font-size: 16px;
    color: $pug-gray-title;
    &.on-focus {
      color: $pug-blue-hover;
    }
  }
  /deep/ .el-input__inner {
    border: none;
    border-bottom: 1px solid $pug-pray-border;
    background-color: #ffffff00;
    border-radius: 0;
    color: $pug-black;
    font-size: 16px;
    padding: 0;
    height: fit-content;
    line-height: 30px;
    font-weight: 700;
    &::placeholder {
      font-weight: 400;
      color: $pug-gray;
      font-size: 16px;
    }
    &:focus {
      font-weight: 400;
      color: $pug-black;
      border-bottom: 1px solid $pug-blue-hover;
    }
  }
  &.error {
    .input-title {
      color: $pug-red;
      &.on-focus {
        color: $pug-red;
      }
    }
    /deep/ .el-input__inner {
      color: $pug-red;
      border-bottom: 1px solid $pug-red;
    }
  }

  /deep/.el-input.is-disabled {
    .el-input__inner {
      font-weight: 400;
      color: $pug-gray;
      cursor: url('../../assets/images/common/not-allowed.png'), not-allowed;
    }
  }
}

.outline {
  /deep/ .el-input__inner {
    border: 1px solid $pug-pray-border;
    padding: 16px 8px;
    height: 48px;
    border-radius: 10px;
    &:focus {
      border: 1px solid $pug-blue-hover;
    }
  }
  &.error {
    /deep/ .el-input__inner {
      border: 1px solid $pug-red;
    }
  }
  /deep/.el-input.is-disabled {
    .el-input__inner {
      background-color: $pug-gray-opacity-10;
      border: 1px solid $pug-gray-opacity-40;
    }
  }
}
</style>
