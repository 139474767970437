export function handleXssInput(value) {
  return value
    ? value
        .replace(/\&/g, '')
        .replace(/\</g, '')
        .replace(/\>/g, '')
        .replace(/\"/g, '')
        // .replace(/\'/g, '') // user name may contain "'"
        .replace(/\//g, '')
    : value
}

export function inhibitInputSpecialCharacters(value) {
  return value && value.replace(/[\-\_\,\.\!\|\~\`\(\)\#\@\%\-\+\=\/\'\$\%\^\&\*\{\}\:\;\"\<\>\?\\]/g, '')
}

export function inhibitInputNumber(value) {
  return value && value.replace(/[0-9]/g, '')
}
